import React from "react";
import "./Blog.css";

function Blog() {
  return (
    <div className="blog__container">
      <h1>Blog</h1>
    </div>
  );
}

export default Blog;
