import React from "react";
import "./Footer.css";

const Footer = () => (
  <div className="footer__container">
    <h3>WEBTECH SOLUTIONS @2024</h3>
  </div>
);

export default Footer;
